import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"

const Projects = () => {
  return (
    <Layout>
      <h3><a href={'https://elevator-4bb03.firebaseapp.com'}>Elevator</a></h3>
      <p>A goal setting app with a focus on habit formation by completing a minimal action towards
      your goal everyday. A react progressive web app built with Firebase.</p>

      <p>Page WIP</p>

    </Layout>
  )
}

export default Projects
